.input_form {
  /* width: 140%; */
}
.item_form {
  width: 150%;
}
.input_pass {
  /* width: 140%; */
  border-radius: 5px;
}
.auto_complete {
  /* border-radius: 5px;
  width: 1000px; */
}
.table_hover table tr:hover {
  color: #1890ff !important;
}
