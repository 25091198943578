.container {
  margin: 5px 10px 15px;
}
.header {
  background-color: white;
}
.content {
  background-color: white;
  margin-top: 10px;
  padding: 15px 20px;
}
.tab {
  /* margin-top: 10px; */
  margin: 15px 0px 10px 10px;
}
.expand {
  background-color: #f6f9ff;
  margin: 0px 10px;
}
.change_password {
  color: black;
  font-size: 14px;
  font-weight: 'bold';
}
.change_password:hover {
  color: #1890ff;
}
.input {
  /* height: 35px; */
  border-radius: 5px;
}
.description_label {
  padding-left: 30px;
}
.table_hover table tr:hover {
  color: #1890ff !important;
  cursor: pointer;
}
.icon_notification {
  font-size: 20px;
  cursor: pointer;
}
.icon_notification:hover {
  color: gray;
}
.wrap_notification {
  background-color: white;
  box-shadow: 2px 2px 10px #878a8a;
  border-radius: 5px;
  width: 350px;
  height: 420px;
}
.title_notification {
  background-color: #5c87db; /*#f0a630*/
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  color: white;
  font-size: 17px;
  font-weight: bold;
  padding: 7px;
  padding-left: 25px;
  height: 12%;
  display: flex;
  justify-content: space-between;
}
.list_notification {
  overflow: scroll;
  overflow-x: unset;
  height: 85%;
  padding: 5px;
}
.list_notification li {
  padding: 8px;
  border-radius: 8px;
  display: flex;
  width: 320px;
}
.list_notification li:hover {
  background-color: #e3e4e6;
  /* background-color: red; */
  cursor: pointer;
}
.left_notification {
  width: 65px;
  display: flex;
  justify-content: center;
  /* align-items: center; */
}

.icon_notice {
  height: 43px;
  width: 43px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  font-size: 26px;
  border-radius: 50%;
}
.contend_notification {
  width: calc(100% - 65px);
  font-size: 13px;
  font-weight: 500;
}
.time_notification {
  justify-content: flex-end;
  display: flex;
  font-size: 12px;
  color: #a8a6a5;
}
