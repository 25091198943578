.title {
  padding: 10px 20px;
}

.time_line {
  padding-right: 8px;
}

.item {
  background-color: white;
  margin-top: 15px;
  border-radius: 5px;
}

.divider {
  width: 100%;
  height: 2px;
  margin-top: 5px;
  background-color: rgb(162, 162, 162);
}

