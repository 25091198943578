.img_array {
  max-width: 100%;
  display: flex;
  flex-wrap: wrap;
}
.icon_delete {
  color: red;
  font-size: 20px;
}
.form {
  width: 110px;
}
.form_content {
  width: 120px;
}
.table_hover table tr:hover {
  color: #1890ff !important;
}
