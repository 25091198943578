@media (min-width: 1341px) {
  .input-search_category {
    min-width: 200px;
  }
  .input-search_categorySub {
    min-width: 200px;
  }

  .select-status_category {
    min-width: 120px;
  }

  .select-status_categorySub {
    min-width: 120px;
  }
  .rangerpicker-category {
    width: 190px;
  }
  .rangerpicker-categorySub {
    width: 200px;
  }
}
@media (min-width: 1490px) {
  .input-search_category {
    min-width: 400px;
  }
  .input-search_categorySub {
    min-width: 400px;
  }

  .select-status_category {
    min-width: 200px;
  }

  .select-status_categorySub {
    min-width: 200px;
  }
}

@media (min-width: 1758px) {
  .input-search_category {
    min-width: 600px;
  }

  .input-search_categorySub {
    min-width: 600px;
  }

  .select-status_category {
    min-width: 250px;
  }

  .select-status_categorySub {
    min-width: 250px;
  }
}
#text_area_category {
  height: 100px;
}
