.App {
  text-align: center;
}

nav {
  display: flex;
  justify-content: space-around;
  align-items: center;
  min-height: 7vh;
  background: rgb(73, 79, 80);
  color: white;
  position: 'fixed';
}

.nav-Links {
  width: 50%;
  display: flex;
  justify-content: space-around;
  align-items: center;
  list-style: none;
}

.card-category .ant-card-body {
  padding: 0px;
}

.card-category .ant-tabs-tab-btn {
  margin-left: 15px;
  margin-right: 15px;
}

.dynamic-delete-button {
  position: relative;
  top: 4px;
  margin: 0 8px;
  color: #999;
  font-size: 24px;
  cursor: pointer;
  transition: all 0.3s;
}
.dynamic-delete-button:hover {
  color: #777;
}
.dynamic-delete-button[disabled] {
  cursor: not-allowed;
  opacity: 0.5;
}

.hidden_col {
  display: none
}