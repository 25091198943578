.cards {
  border-radius: 5px;
  border-width: 2px;
  border-style: solid;
  width: 200px;
  height: 80px;
  text-align: center;
  justify-content: center;
  line-height: 80px;
  font-size: 18px;
  font-weight: 500;
}
